<template>
  <Layout>
    <BHeader>
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        @submit.native.prevent
      >
        <el-form-item prop="keyWord">
          <el-input
            v-model="formData.keyWord"
            placeholder="角色名"
            clearable
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleEditOrCreateRole()"
        >
          新增角色
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="rolesListInfo.loading"
      :data="rolesListInfo.lists"
      style="width: 100%;"
      stripe
      highlight-current-row
    >
      <el-table-column
        align="center"
        label="Role ID"
        width="220"
      >
        <template v-slot="{row}">
          {{ row.id }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="Role Name"
        width="220"
      >
        <template v-slot="{row}">
          {{ row.roleName }}
        </template>
      </el-table-column>
      <el-table-column
        align="header-center"
        label="Description"
      >
        <template v-slot="{row}">
          {{ row.roleDocs }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        label="Operations"
        width="160px"
      >
        <template v-slot="{row}">
          <el-button
            size="small"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="handleEditOrCreateRole(row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="rolesListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryRolesList(formData)"
    />
    <el-drawer
      :title="drawerType==='edit'?'Edit Role':'New Role'"
      :visible.sync="drawerVisible"
      direction="rtl"
      size="650px"
    >
      <el-form
        ref="role"
        :model="currentRole"
        :rules="roleRules"
        label-width="100px"
        label-position="left"
      >
        <el-form-item
          label="Name"
          prop="roleName"
        >
          <el-input
            v-model="currentRole.roleName"
            clearable
            placeholder="Role Name"
          />
        </el-form-item>
        <el-form-item
          label="Desc"
          prop="roleDocs"
        >
          <el-input
            v-model="currentRole.roleDocs"
            :autosize="{ minRows: 2, maxRows: 4}"
            clearable
            type="textarea"
            placeholder="Role Description"
          />
        </el-form-item>
        <el-form-item label="Menus">
          <PermissionTree
            ref="permissionTree"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:right;">
        <el-button
          @click="drawerVisible = false"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="confirmLoading"
          @click="handleConfirm"
        >
          确认
        </el-button>
      </div>
    </el-drawer>
  </Layout>
</template>

<script>
import PermissionTree from './components/PermissionTree'
import { cloneDeep } from 'lodash'
import { deleteRole, addRole, updateRole } from '@/api/permission'
import { mapState, mapActions } from 'vuex'
const defaultRole = {
  key: '',
  name: '',
  description: '',
  permission: []
}
export default {
  name: 'RoleManagement',
  components: {
    PermissionTree
  },
  data () {
    return {
      formData: {
        keyWord: '',
        pageNum: 1,
        pageSize: 20
      },
      drawerType: 'new',
      currentRole: {},
      drawerVisible: false,
      roleRules: {
        roleName: [{ required: true, message: '角色名称为必填项' }],
        roleDocs: [{ required: true, message: '角色描述为必填项' }]
      },
      confirmLoading: false
    }
  },
  computed: {
    ...mapState('permission', ['rolesListInfo'])
  },
  created () {
    this.queryRolesList(this.formData)
  },
  methods: {
    ...mapActions('permission', ['queryRolesList']),
    handleSearch () {
      this.pageNum = 1
      this.queryRolesList(this.formData)
    },
    handleEditOrCreateRole (row) {
      const isEdit = !!row
      this.drawerType = isEdit ? 'edit' : 'new'
      this.drawerVisible = true
      this.currentRole = cloneDeep(isEdit ? row : defaultRole)
      this.$nextTick(() => {
        const checkedKeys = isEdit ? (row.authCode || []).concat(row.authRouter || []) : []
        this.$refs.permissionTree.setCheckedKeys(checkedKeys)
        this.$refs.role.clearValidate()
      })
    },
    handleDelete (row) {
      this.$confirm('确定删除这个角色吗?', 'Warning', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const result = await deleteRole({ id: row.id })
          if (result.code === 200) {
            this.queryRolesList(this.formData)
            this.$notify({
              title: '成功',
              type: 'success',
              message: '删除成功!'
            })
          }
        })
        .catch(err => { console.error(err) })
    },
    handleConfirm () {
      this.$refs.role.validate(async valid => {
        if (valid) {
          const isEdit = this.drawerType === 'edit'
          const checkedKeys = this.$refs.permissionTree.getCheckedKeys()
          let result = {}
          this.confirmLoading = true
          try {
            if (isEdit) {
              result = await updateRole({
                id: this.currentRole.id,
                roleName: this.currentRole.roleName,
                roleDocs: this.currentRole.roleDocs,
                ...checkedKeys
              })
            } else {
              result = await addRole({
                roleName: this.currentRole.roleName,
                roleDocs: this.currentRole.roleDocs,
                ...checkedKeys
              })
            }
            if (result.code === 200) {
              // 重新拉取新数据
              await this.handleSearch()
              const { roleDocs, roleName } = this.currentRole
              this.drawerVisible = false
              this.$notify({
                title: 'Success',
                dangerouslyUseHTMLString: true,
                message: `
              <div>Role Nmae: ${roleName}</div>
              <div>Description: ${roleDocs}</div>
            `,
                type: 'success'
              })
            } else {
              this.$message.error(result.data.message)
            }
          } catch {
            this.confirmLoading = false
          }
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>

<style  lang="less">
  .el-drawer{
    &:focus{
      outline: none;
    }
    .el-drawer__header{
      >span{
        &:focus{
          outline: none;
        }
      }
      .el-drawer__close-btn{
        &:focus{
          outline: none;
        }
        &:hover{
          color: @primary;
        }
      }
    }
    .el-drawer__body{
      padding: 0 30px 0 20px;
    }
  }
  .zIndex4000{
    z-index: 4000 !important;
  }
</style>
